import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import cx from 'classnames';
import FormInput from '../../../../commonComponents/forms/FormInput';
import Form from '../../../../commonComponents/forms/Form';
import Notifications from '../../../../services/notifications';

function ConnectorMBE(props) {
  const { t, _csrf, onChange, mbeService, isAuthenticated } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      const method = !isAuthenticated ? 'put' : 'delete';
      const endpoint = '/api/auth/organization/services/mbe';
      let params = {};
      const config = {
        headers: {
          'Accept-Version': '1.0.0',
          'X-Gamification-API': 'Auth',
          'X-CSRF-Token': _csrf,
          _csrf,
        },
      };

      if (!isAuthenticated) {
        params = {
          serviceData: {
            config: {
              apiKey: mbeService.apiKey,
            },
          },
        };
      }

      await axios[method](
        endpoint,
        !isAuthenticated ? params : config,
        !isAuthenticated ? config : undefined,
      );

      Notifications.showNotificationSuccess(
        t('success'),
        !isAuthenticated
          ? t('authenticated.success')
          : t('authentication.remove.successfully'),
      );
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      Notifications.showErrorFromRequest(
        err,
        !isAuthenticated
          ? 'authenticated.fail'
          : 'authentication.remove.failed',
      );
    }
  }, [isAuthenticated, mbeService, setIsLoading, t]);

  const buttonCx = cx(
    'btn',
    'btn-dynamic',
    { 'button-authenticate': !isAuthenticated },
    { 'button-remove-auth': isAuthenticated },
    { 'm-loader': isLoading },
    { 'm-loader--right': isLoading },
    { 'm-loader--light': isLoading },
  );

  return (
    <div id="mbeServiceContainer">
      <Form
        validationRules={{ mbeKey: { required: true } }}
        submitMethod={handleClick}
        id="mbeForm"
      >
        {submitHandler => (
          <>
            <h4 className="title">{t('service_type_mbe')}</h4>
            <FormInput
              id="mbeKey"
              name="mbeKey"
              label={t('service.apiKey')}
              onChange={onChange('apiKey')}
              value={mbeService.apiKey}
              type="password2"
              containerClassName="apiKey-inputContainer"
              disabled={isAuthenticated}
              inputProps={{
                showEyeIcon: !isAuthenticated,
                autocomplete: 'off',
              }}
            />
            <div className="button-container">
              <button
                type="button"
                onClick={submitHandler}
                className={buttonCx}
                disabled={isLoading}
              >
                {!isAuthenticated
                  ? t('authenticate')
                  : t('authentication.remove')}
              </button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
}

ConnectorMBE.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  mbeService: PropTypes.shape({
    apiKey: PropTypes.string,
  }).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default ConnectorMBE;
