import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';
import ConnectorMBE from '../../sections/settings/configurations/connectors/ConnectorMBE';
import organizationProp from '../../commonComponents/propTypes/organizationProp';

const ConnectorMBEWebHandler = forwardRef((props, ref) => {
  const { propagateChanges, ...childProps } = props;
  const { organization, _csrf } = props;
  const { mbe: mbeService } = organization?.services || {};
  const [mbe, setMbe] = useState(mbeService?.config || {});
  useEffect(() => propagateChanges(mbe), [mbe]);

  const handleChanges = useCallback(
    field => value => {
      setMbe(prevMBE => ({ ...prevMBE, [field]: value }));
    },
    [setMbe],
  );

  useImperativeHandle(ref, () => ({
    getValue: () => mbe,
    setValue: newMBE => setMbe(newMBE),
  }));

  return (
    <>
      <ConnectorMBE
        _csrf={_csrf}
        mbeService={mbe}
        onChange={handleChanges}
        isAuthenticated={mbeService?.config?.configured}
        {...childProps}
      />
    </>
  );
});

ConnectorMBEWebHandler.propTypes = {
  t: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
  propagateChanges: PropTypes.func.isRequired,
  organization: PropTypes.shape(organizationProp.propType).isRequired,
};

ConnectorMBEWebHandler.defaultProps = {};

window.ConnectorMBE = class ConnectorMBERenderer {
  constructor(divId) {
    this.divId = divId;
    this.ref = null;
    this.listeners = {};
  }

  init(divId, props) {
    if (divId) {
      this.divId = divId;
    }

    const divEl = document.getElementById(divId);
    if (!divEl) {
      throw 'ConnectorMBE container not found';
    }

    let finalProps = {};

    const propsData = divEl.getAttribute('data-props');
    if (propsData) {
      finalProps = JSON.parse(propsData);
    }

    const data = document.querySelector('script[data-react-props]');
    let globalProps = {};
    if (data) {
      globalProps = JSON.parse(data.getAttribute('data-react-props'));
    }

    if (props) {
      finalProps = { ...globalProps, ...finalProps, ...props };
    }

    ReactDOM.render(
      <ConnectorMBEWebHandler
        ref={c => {
          this.ref = c;
        }}
        t={i18n.__}
        propagateChanges={changes => this.emit('change', changes)}
        {...finalProps}
      />,
      divEl,
    );
    return this;
  }

  val(value) {
    if (value) {
      this.ref.setValue(value);
      return undefined;
    }

    return this.ref.getValue();
  }

  on(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      for (let i = 0; i < this.listeners[type].length; i += 1) {
        if (this.listeners[type][i]) {
          this.listeners[type][i](...args);
        }
      }
    }
  }
};
